.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background-color: rgb(128 73 249 / 30%);
  margin-top: 0;
}

.title {
  font-weight: 700 !important;
  font-family: "Sora", sans-serif !important;
  font-size: 24px !important;
  @media (max-width: 600px) {
    font-size: 18px !important;
  }
}

.grid {
  padding: 10px!important;
  margin-top: 0!important;
}

.gridItem {
  padding-top: 0;
}

.gridItemContent {
  width: 40px;
  height: 40px;
  border: 2px solid white;
  padding: 12px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 600px) {
    width: 30px;
    height: 30px;
  }
}

.itemTitle {
  line-height: 1.2 !important;
  font-family: "Sora", sans-serif !important;
  @media (max-width: 600px) {
    font-size: 14px !important;
  }
}

.itemValue {
  font-family: "Sora", sans-serif !important;
  @media (max-width: 600px) {
    font-size: 10px !important;
  }
}
